import { faChartBar, faClock, faCode, faVideo } from "@fortawesome/free-solid-svg-icons"
import React from "react"
import {CoursePageFull} from "../components/course-pages/CoursePageFull"
import { graphql, StaticQuery } from "gatsby"

export default class CorsoAngularNgrx extends React.Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            bigPic: file(relativePath: { eq: "gatsby-astronaut.png" }) { ...imgTrainingBig },
            logo_ngrx_angular: file(relativePath: { eq: "logo-ngrx-angular.png" }) { ...imgTrainingBig },

            carouselIntro1: file(relativePath: { eq: "courses/angular-ngrx/carousel-intro/01.jpeg" }) { ...imgTrainingBig },
            carouselIntro2: file(relativePath: { eq: "courses/angular-ngrx/carousel-intro/02.jpeg" }) { ...imgTrainingBig },
            carouselIntro3: file(relativePath: { eq: "courses/angular-ngrx/carousel-intro/03.jpeg" }) { ...imgTrainingBig },
            carouselIntro4: file(relativePath: { eq: "courses/angular-ngrx/carousel-intro/04.jpeg" }) { ...imgTrainingBig },

            review_daniela_ferrante: file(relativePath: { eq: "courses/angular-fundamentals/reviews/review_daniela_ferrante.png" }) { ...imgTrainingBig },
            review_zack_nero: file(relativePath: { eq: "courses/angular-fundamentals/reviews/review_zack_nero.png" }) { ...imgTrainingBig },
            review_marco_brunet: file(relativePath: { eq: "courses/angular-fundamentals/reviews/review_marco_brunet.png" }) { ...imgTrainingBig },

            mode1: file(relativePath: { eq: "courses/_general/mode/mode-thumb-1.png" }) { ...thumb },
            mode2: file(relativePath: { eq: "courses/_general/mode/mode-thumb-2.png" }) { ...thumb },
            mode3: file(relativePath: { eq: "courses/_general/mode/mode-thumb-3.png" }) { ...thumb },
            profile: file(relativePath: { eq: "courses/_general/profile/profile-round-gde-1.png" }) { ...thumb },
            pricing_bg: file(relativePath: { eq: "courses/angular-ngrx/pricing/bg_price_1.png" }) { ...thumb },

          }
        `}
        render={data => <CoursePageFull {...this.props}  data={data} course={COURSE_INFO}/> }
      />
    )
  }
}

export const COURSE_INFO = {
  theme: {
    separatorHeaderColor1: '#F9E4F0',
    separatorHeaderColor2: '#E1A2EC',
    separatorColor1: '#412846',
    separatorColor2: '#FF7272',
    buttonColor: '#e7605e'
  },
  header: {
    titleSEO: 'Corso State Manager & Data Architectures in Angular & NGRX',
    title: 'STATE MANAGER & <span>DATA ARCHITECTURES</span> <br/>\nCON <strong>ANGULAR</strong>8 e <strong>NGRX</strong>8',
    shortTitle: 'DATA ARCHITECTURES <br />IN <strong>ANGULAR</strong>8 e <strong>NGRX</strong>8',
    subtitle: 'Un video corso su NGRX per la creazione di applicazioni enterprise in Angular, scalabili e manutenibili',
    image: 'logo_ngrx_angular',
    difficulty: 'avanzato',
    duration: '9 ore'
  },
  description: [
    { title: 'INTRODUZIONE', text: 'Angular fornisce gli strumenti fondamentali per sviluppare un’intera Single Page Application. Tuttavia, le attività che il front-end deve svolgere sono sempre più complesse e le interfacce utente (UI), di conseguenza, stanno diventando sempre più sofisticate: gestione di un data-flow molto articolato, componenti che devono rimanere in sync tra di loro, integrazione unit test, time travel debugging sono solo alcune delle problematiche ricorrenti che uno sviluppatore front-end deve affrontare quotidianamente. Redux, libreria nata per requisiti complessi in contesti quali Facebook, nasce con l’obiettivo di separare nettamente gli aspetti architetturali dalla user interface, semplificando la gestione dello stato applicativo e rendendo il codice molto più manutenibile, testabile e scalabile.' },
    { title: 'COS\'È <strong>NGRX</strong>', text: 'NGRX è una libreria ispirata a Redux creata per gestire lo stato applicativo in applicazioni Angular e ne condivide diversi aspetti: azioni, reducers e un singolo store. I dati sono esposti sotto forma di Observable (quindi fa largo uso di RxJS) tramite selezioni dello stato e fornisce una moltitudine di pattern e utility per gestire inoltre operazioni asincrone, effects/middlewares, typed actions, meta-reducers, selectors / memoization, lazy loading, entità e molto altro.' },
  ],
  metadata: [
    { title: 'OBIETTIVO DEL CORSO', text: 'Creare applicazioni Angular scalabili, testabili e semplici da mantenere utilizzando NGRX per la gestione dello stato applicativo, applicando una netta separazione tra architettura dati e presentational layer.' },
    { title: 'REQUISITI', text: 'Per comprendere gli argomenti trattati nel corso è necessario avere una buona conoscenza delle seguenti funzionalità del framework Angular: componenti custom, moduli, dependency injection, router e lazy loading.' },
  ],
  carousels: {
    main: [
      {img: 'carouselIntro1', desc: 'Un abuso della dependency injection porta spesso ad uno stato unpredictable'},
      {img: 'carouselIntro2', desc: 'Gestire il flusso dati attraverso uno state manager permette di creare una UI totalmente stateless / "stupida"'},
      {img: 'carouselIntro3', desc: 'NGRX permette di organizzare e gestire ogni fase dello stato applicativo'},
      {img: 'carouselIntro4', desc: '... e di organizzare queste fasi in specifici file utilizzando convenzioni e best practices ormai consolidate'}
    ],
    reviews: [
      {img: 'review_daniela_ferrante', desc: ''},
      {img: 'review_zack_nero', desc: ''},
      {img: 'review_marco_brunet', desc: ''},
    ],
  },
  videos: {
    teaser1: 'ovqUhtgJC-M',
  },
  summary: [
    { icon: faClock, label: 'Durata:', value: '9 ore' },
    { icon: faChartBar, label: 'Livello:', value: 'Avanzato' },
    { icon: faCode, label: 'Versione Angular:', value: '8.x' },
    { icon: faCode, label: 'Versione NGRX:', value: '8.x' },
    { icon: faVideo, label: 'Tipologia:', value: 'Video Corso' },
  ],
  exampleLesson: {
    enabled: true,
    title: 'ESEMPIO LEZIONE',
    desc: 'In questa lezione:',
    video1: 'wEDOZvI_Tdg',
    items: [
      { text: 'Punto 1'},
      { text: 'Punto 2'},
      { text: 'Punto 3'},
      { text: 'Punto 4'},
      { text: 'Punto 5'},
    ]
  },
  mode: {
    colLeft: {
      img: 'mode1',
      title: 'SINGOLI ESEMPI',
      text: 'Decine di esempi pratici e casi d’uso sulle principali funzionalità del framework, le API più popolari e le best practices più utili per la creazione di codice riutilizzabile e scalabile.',
    },
    colCenter: {
      img: 'mode2',
      title: 'REAL APPS',
      text: 'Video lezioni dedicate alla teoria in cui saranno descritti i concetti tramite l’utilizzo di slide, diagrammi animati e materiale esclusivo.\n',
    },
    colRight: {
      img: 'mode3',
      title: 'TEORIA',
      text: 'Sessioni di live coding in cui saranno sviluppate Single Page Application che comunicano via API RESTful o RealTime Application che utilizzano Google Firebase per la sincronizzazione tra diversi client.\n'
    }
  },
  program: {
    colLeft: [
      { type: 'title', value: 'INTRODUZIONE A REDUX e NGRX'},
      { value: '• Principi fondamentali di Redux'},
      { value: '• Immutable State'},
      { value: '• Pure vs Inpure functions'},
      { value: '• Reducers'},
      { value: '• Store'},
      { value: '• Actions'},
      { value: '• Containers vs Presentational components'},
      { value: '• Esempi e casi d’uso'},
    ],
    colRight: [
      { type: 'title', value: 'NGRX: REACTIVE STATE MANAGER'},
      { value: '• Data architectures in Angular e NGRX'},
      { value: '• Store e stato applicativo reattivo'},
      { value: '• Utilizzo di Observable, RxJS e async pipe'},
      { value: '• Azioni, sincrone e asincrone'},
      { value: '• Reducer: aggiornare lo stato applicativo'},
      { value: '• Typed Actions e Action Creator'},
      { value: '• NGRX Effects'},
      { value: '• Features Modules, lazy loading e lazy store'},
      { value: '• Metareducers'},
      { value: '• Combine Reducers'},
      { value: '• Introduzione alle entity di NGRX'},
      { value: '• Debugging e Redux Chrome Dev tools'},
    ],
  },
  pricing: {
    price: 397,
    priceOffer: 347,
    priceOfferDescription: 'Offerta valida fino al 31 agosto 2019',
    items: [
      { label: 'Video Corso ANGULAR FUNDAMENTALS', extra: '10 ore'},
      { label: 'Mini video corso JAVASCRIPT ES6', extra: '2 ore'},
      { label: 'Accesso ad Area educational', extra: 'Slide & Esercizi'},
      { label: 'CheatSheet NGRX ', extra: 'PDF formato A4'},
      { label: 'Codice Sorgente', extra: 'Incluso'},
      { label: 'Attestato di partecipazione', extra: 'PDF formato A4'},
      { label: 'Soddisfatto o Rimborsato', extra: 'Entro 30gg'},
    ],
    button: {
      enabled: true,
      visible: true,
      buyText: 'PREORDINA',
      buyLink: null,
      target: '_scrollTo',
      scrollToID: '#newsletter'
    },
    bg: 'pricing_bg',
  },
  newsletter: {
    enabled: true,
    campaignFormUrl: 'https://fabiobiondi.us16.list-manage.com/subscribe/post?u=2478e04b9171b207e77c6cbc0&amp;id=85573d8b21',
  },
  actionButtons: {
    main: {
      enabled: true,
      visible: true,
      buyText: 'PREORDINA',
      buyLink: null,
      target: '_scrollTo',
      scrollToID: '#newsletter'
    }
  }
}
